import React from "react";
import "./App.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Router } from "./routes";

function App() {
  return <Router />;
}

export default App;
