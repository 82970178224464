import React from "react";

import "./styles.scss";

export function Loading() {
  return (
    <main id="loading-component">
      <div className="container">
        <div className="loading">
          <div id="color1" />
          <div id="color2" />
          <div id="color3" />
        </div>
        <h6>Carregando ...</h6>
      </div>
    </main>
  );
}
