import React from "react";

import { Routes, Route, HashRouter } from "react-router-dom";
import { Loading } from "../components/Loading";
// import SistemaPDVLaunch2 from "../pages/SistemaPDVLaunch copy";

// const SistemaPDVBeta = React.lazy(() => import("../pages/SistemaPDVBeta"));
const SistemaPDVLaunch2 = React.lazy(() => import("../pages/SistemaPDVLaunch copy"));
const FormPDVBeta = React.lazy(() => import("../pages/FormPDVBeta"));
const Thank = React.lazy(() => import("../pages/Thank"));

export function Router() {
  return (
    <HashRouter>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<SistemaPDVLaunch2 />} />
          <Route path="/pdv" element={<SistemaPDVLaunch2 />} />
          <Route path="/launch" element={<SistemaPDVLaunch2 />} />
          <Route path="/formulario-pdv" element={<FormPDVBeta />} />
          <Route path="/entraremos-em-contato" element={<Thank />} />
          {/* <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          /> */}
          <Route path="*" element={<SistemaPDVLaunch2 />} />
        </Routes>
      </React.Suspense>
    </HashRouter>
  );
}
